import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import countries from 'countries-list';
import format from 'date-fns/format';

import Layout from '../../components/Layout';
import Dropdown from '../../components/DropdownRequired';

import mondayConfig from '../../constants/monday-config';

import iconTick from '../../assets/images/svg/tick.svg';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import '../../assets/styles/pages/work-with-us-page.scss';

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map((code) => countries.countries[code].name);
const countryNamesOptions = countryNames.sort().map((country) => ({
  value: country,
  label: country,
}));

const EMPTY_FORM_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
  userPhone: '',
  userNationality: '',
  teachingQualifications: '',
  otherTeachingQualifications: '',
  statusInSpain: '',
  otherStatusInSpain: '',
  userCV: '',
  userCoverLetter: '',
};

const WorkWithUs = () => {
  const [formData, setFormData] = useState(EMPTY_FORM_DATA);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSelectChange = (event, options) => {
    const { value } = event;
    const { name } = options;
    setFormData({ ...formData, [name]: value });
  };
  // const handleFileChange = (event) => {
  //   const { name, files } = event.target;
  //   setFormData({ ...formData, [name]: files[0] });
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const mondayData = {
      BOARD_ID: mondayConfig.boardEnglishTeacherCv,
      GROUP_ID: mondayConfig.groupId,
      itemName: `${formData.firstName} ${formData.lastName}`,
      values: {
        status: { label: 'TEFL Iberia' },
        date4: mondayConfig.date,
        text: formData.firstName,
        text6: formData.lastName,
        email4: formData.userEmail,
        telephone: formData.userPhone,
        text5: formData.userNationality,
        qualifications:
          formData.teachingQualifications + ' ' + formData.otherTeachingQualifications,
        status_1: { label: formData.statusInSpain },
        text0: formData.otherStatusInSpain,
        // files: formData.userCV,
        long_text: formData.userCoverLetter,
      },
    };
    await fetch('/api/monday', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mondayData),
    });

    const MailchimpData = {
      FNAME: formData.firstName,
      LNAME: formData.lastName,
      PHONE: formData.userPhone,
      MMERGE3: 'TEFL Iberia', // Source
      MMERGE5: '', // Status
      MMERGE7: format(new Date(), 'MMMM do YYY'), // contact date
      MMERGE8: formData.userNationality,
      MMERGE9: formData.teachingQualifications + ' ' + formData.otherTeachingQualifications,
      MMERGE10: formData.statusInSpain,
      MMERGE11: formData.otherStatusInSpain,
      MMERGE13: formData.userCoverLetter,
    };

    addToMailchimp(
      formData.userEmail,
      MailchimpData,
      'https://tefl-iberia.us9.list-manage.com/subscribe/post?u=14228e00bf6efc88dcde365c5&amp;id=62abc495cb'
    );
    setFormData(EMPTY_FORM_DATA);

    navigate('/thank-you');

    // const fileData = new FormData();
    // const fileField = document.querySelector('input[type="file"]');
    // fileData.append('file', fileField.files[0]);
    // await fetch('/api/monday-asset', {
    //   method: 'POST',
    //   body: fileData,
    // });

    // const sourceLabel = 'TEFL Brochure';
    // const MailchimpData = {
    //   FNAME: formData.firstName,
    //   LNAME: formData.lastName,
    //   MMERGE4: new Date().toISOString().split('T')[0], // contact date
    //   MMERGE17: sourceLabel, // Source
    // };
  };

  return (
    <Layout title={{ id: 'wWithUs.seo.title' }} description={{ id: 'wWithUs.seo.description' }}>
      <div className="work-with-us-page">
        <section className="first-section">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <div className="wrapper__inner">
                  <h1 className="c-title-42 first-section__title">
                    <FormattedMessage id="wWithUs.sec1.title" />
                  </h1>
                  <p className="c-text-18 first-section__description">
                    <FormattedMessage id="wWithUs.sec1.description" />
                    <br />
                    <FormattedMessage id="wWithUs.sec1.description2" />
                  </p>
                </div>
              </div>
            </div>
            <div className="row c-benefit-list offseted">
              <div className="col-12 col-lg-4 col-md-6">
                <div className="item">
                  <h4 className="item__title c-text-16">
                    <FormattedMessage id="wWithUs.sec1.c1.benefitsTitle" />
                  </h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      {' '}
                      <FormattedMessage id="wWithUs.sec1.c1.ben1" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c1.ben2" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c1.ben3" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c1.ben4" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c1.ben5" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c1.ben6" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="item">
                  <h4 className="item__title c-text-16">
                    <FormattedMessage id="wWithUs.sec1.c2.benefitsTitle" />
                  </h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c2.ben1" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c2.ben2" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c2.ben3" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c2.ben4" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="item">
                  <h4 className="item__title c-text-16">
                    <FormattedMessage id="wWithUs.sec1.c3.benefitsTitle" />
                  </h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c3.ben1" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c3.ben2" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c3.ben3" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="wWithUs.sec1.c3.ben4" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="center mb-5">
                  <p className="c-text-16">
                    <FormattedMessage id="wWithUs.sec2.notice" />
                  </p>
                </div>
                <form onSubmit={handleSubmit} className="form">
                  <h1 className="c-title-22 form__title">
                    <FormattedMessage id="wWithUs.sec2.form" />
                  </h1>

                  <label className="form-half">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="wWithUs.sec2.firstName" />
                    </p>
                    <input
                      className="input-half"
                      type="text"
                      placeholder="John"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  <label className="form-half ml-3">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="wWithUs.sec2.lastName" />
                    </p>
                    <input
                      className="input-half"
                      type="text"
                      placeholder="Smith"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </label>

                  <label className="form-half">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="wWithUs.sec2.email" />
                    </p>
                    <input
                      className="input-half"
                      type="email"
                      placeholder="johnsmith@gmail.com"
                      name="userEmail"
                      value={formData.userEmail}
                      onChange={handleInputChange}
                      required
                    />
                  </label>

                  <label className="form-half ml-3">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="wWithUs.sec2.tel" />
                    </p>
                    <input
                      className="input-half"
                      type="tel"
                      placeholder="+34 7841-15-58"
                      name="userPhone"
                      value={formData.userPhone}
                      onChange={handleInputChange}
                      required
                    />
                  </label>

                  <label className="form-full">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="wWithUs.sec2.nationality" />
                    </p>
                    <Dropdown
                      name="userNationality"
                      options={countryNamesOptions}
                      onChange={handleSelectChange}
                      value={formData.userNationality}
                      isSearchable
                      required
                    />
                  </label>

                  <label className="form-full">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="wWithUs.sec2.qualification" />
                    </p>
                    <Dropdown
                      name="teachingQualifications"
                      options={[
                        { value: 'Online TEFL course', label: 'Online TEFL course' },
                        { value: 'Weekend TEFL course', label: 'Weekend TEFL course' },
                        { value: 'CELTA', label: 'CELTA' },
                        { value: 'Trinity CertTESOL', label: 'Trinity CertTESOL' },
                        { value: 'DELTA', label: 'DELTA' },
                        { value: 'DipTESOL', label: 'DipTESOL' },
                        { value: 'MA TESOL', label: 'MA TESOL' },
                        { value: 'PGCE', label: 'PGCE' },
                        {
                          value: 'Further professional development course',
                          label: 'Further professional development course',
                        },
                        { value: 'Other', label: 'Other' },
                      ]}
                      placeholder="Select your preferred choice"
                      onChange={handleSelectChange}
                      value={formData.teachingQualifications}
                      required
                    />
                    {formData.teachingQualifications === 'Other' && (
                      <input
                        className="mt-2"
                        type="text"
                        placeholder="Other..."
                        name="otherTeachingQualifications"
                        value={formData.otherTeachingQualifications}
                        onChange={handleInputChange}
                        required
                      />
                    )}
                  </label>

                  <label className="form-full">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="wWithUs.sec2.spainStatus" />
                    </p>
                    <Dropdown
                      name="statusInSpain"
                      options={[
                        {
                          value: 'Full work permit',
                          label: 'Full work permit (NIE and Social Security number)',
                        },
                        { value: 'Autónomo', label: 'Autónomo' },
                        { value: 'Student visa', label: 'Student visa' },
                        { value: 'Tourist visa', label: 'Tourist visa' },
                        { value: 'Other', label: 'Other' },
                      ]}
                      placeholder="Select your preferred choice"
                      onChange={handleSelectChange}
                      value={formData.statusInSpain}
                      required
                    />
                    {formData.statusInSpain === 'Other' && (
                      <input
                        className="mt-2"
                        type="text"
                        placeholder="Other..."
                        name="otherStatusInSpain"
                        value={formData.otherStatusInSpain}
                        onChange={handleInputChange}
                        required
                      />
                    )}
                  </label>

                  <label className="form-full">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="wWithUs.sec2.coverLetter" />
                    </p>
                    <textarea
                      name="userCoverLetter"
                      rows="10"
                      value={formData.userCoverLetter}
                      onChange={handleInputChange}
                      required
                    />
                  </label>

                  {/* <label className="form-half">
                    <p className="c-text-14 text-under">Please upload your CV in pdf format</p>
                    <input
                      // accept="application/pdf"
                      type="file"
                      placeholder="+34 7841-15-58"
                      name="userCV"
                      onChange={handleFileChange}
                      // required
                    />
                  </label> */}

                  <button type="submit" className="form__btn c-btn c-btn--red">
                    <FormattedMessage id="wWithUs.sec2.apply" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default WorkWithUs;
