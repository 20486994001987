import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '3px',
    border: '1px solid rgba(27,32,50,.1)',
    padding: '12px 16px',
  }),
  indicatorSeparator: () => ({}),
  placeholder: (provided) => ({ ...provided, color: '#8f93a2', fontSize: 14 }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#c91c20',
  }),
};

const Dropdown = ({ name, value, required, options, placeholder, ...props }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Select
        name={name}
        styles={customStyles}
        placeholder={placeholder}
        options={options}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#1b20321a',
            primary25: '#e6e6e6',
          },
        })}
        {...props}
      />
      {!props.disabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, width: '100%', height: 0, position: 'absolute', top: 0 }}
          value={value}
          required={required}
        />
      )}
    </div>
  );
};

export default Dropdown;
